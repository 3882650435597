/*global require, requirejs, define */
var require = {
	paths: {
		//Only include the modules that are used
		'bootstrap-collapse': 'Vendor/Bootstrap/collapse',
		'bootstrap-dropdown': "Vendor/Bootstrap/dropdown",
		'bootstrap-transition': "Vendor/Bootstrap/transition",
		'bootstrap-modal': "Vendor/Bootstrap/modal",
		'jquery': 'Vendor/jquery',
		'isMobile': 'Vendor/isMobile',
		//replace with swiper.js in the scripts-backup folder if you don't want jQuery dependency
		'swiper': 'Plugins/swiper.jquery',
		'helpers': 'Modules/helpers',
		'facebook': '//connect.facebook.net/en_US/sdk',
		'jquery-validate': 'Plugins/jquery.validate.min',
		'jquery-validate-mvc': 'Plugins/jquery.validate.unobtrusive.min',
		'validator': 'Modules/validator'
	},
	shim: {
		'bootstrap-transition': ['jquery'],
		'bootstrap-collapse': ['bootstrap-transition'],
		'bootstrap-dropdown': ['jquery'],
		'bootstrap-modal': ['jquery'],
		//remove if you are using the stand-alone swiper.js
		'swiper': ['jquery'],
		'facebook': {exports: 'FB'},
		'jquery-validate': ['jquery'],
		'jquery-validate-mvc': ['jquery-validate']
	}
};

var attrMain = document.body.attributes['data-main'];

if (attrMain) {
	var jsMain = attrMain.value;
	var matches = jsMain.match(/^([\w-]+)\.js$/);
	if (matches) {
		jsMain = matches[1];
	}
	require.deps = [jsMain];
}

requirejs.config(require);

requirejs.onError = function (err) {
	'use strict';
	console.error(err.stack);
};

//add shared modules in the common dependencies
requirejs([
		'jquery',
		'isMobile',
		'helpers',
		'bootstrap-collapse',
		'bootstrap-dropdown',
		'validator',
		'jquery-validate-mvc',
		'bootstrap-modal',
		'Modules/donate-modal'
	],
	function ($, isMobile, helpers) {
		'use strict';

		var Settings = {
			$donateModal: $('.js-donate-modal'),
			$subscribeModal: $('.js-subscribe-modal')
		};

		function openSubscribeModal(e) {
			e.preventDefault();
			Settings.$subscribeModal.modal();
		}

		var bind = function () {

			$('.navbar-toggle').on('click', function () {
				helpers.$body.toggleClass('no-scroll');
			});

			$('.dropdown-toggle').on('click', function (e) {
				var $this = $(this);
				e.preventDefault();

				if (!helpers.smallScreen()) {
					window.location = $this.attr('href');
				} else {
					if ($this.parent('.dropdown').hasClass('open')) {
						window.location = $this.attr('href');
						return false; // Stop the dropdown from closing again just before redirecting
					}
				}
			});

			$('.js-subscribe-link').on('click', openSubscribeModal);

		};

		bind();

	});

define("common", function(){});

