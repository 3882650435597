define('validator',['jquery',
    'jquery-validate'],
function ($) {
    /**
     * Toggles the client-side validation highlight on forms
     */
    function _init() {
        $.validator.setDefaults({
            highlight: function (element) {
                var $formGroup = $(element).closest(".form-group");
                $formGroup.addClass("has-error");                
            },
            unhighlight: function (element) {
                var $formGroup = $(element).closest(".form-group");
                $formGroup.removeClass("has-error");                
            }
        });        
    }
    
    _init();
});
