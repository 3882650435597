define('Modules/donate-modal',[
	'jquery',
	'helpers'
], function ($, helpers) {
	'use strict';

	helpers.$document.ready(function () {
		var $directDebitLink,
			$creditCardLink,
			$description,
			descriptionTexts,
			$donationAmountButtons,
			$donationAmount,
			$donateModal,
			donatePageCreditCardUrl,
			donatePageDirectDebitUrl,
			$paypalDonateWrapper,
			$paypalForm,
			$paypalAmount;

		$creditCardLink = $('.js-donate-modal--credit-cards');
		$directDebitLink = $('.js-donate-modal--direct-debit');
		$donationAmount = $('.donation-amount');
		$donationAmountButtons = $('.js-donate-modal--amount-btn');
		$description = $('.js-donate-modal--description');
		$donateModal = $('.js-donate-modal');
		$paypalDonateWrapper = $('.js-donate-modal--paypal');
		$paypalForm = $paypalDonateWrapper.find('form');
		$paypalAmount = $paypalForm.find('input[name="amount"]');

		descriptionTexts = [
			{
				id: '33',
				text: '$33 can provide a special gift to a child of a deceased police officer under the age of 16 for their birthday and at Christmas time. It’s a special reminder to let them know that the police family is thinking of them and that they are not alone.'
			},
			{
				id: '69',
				text: '$69 can provide Care Alert funding for police legatees over the age of 65 to ensure they have the peace of mind and protection in case of emergencies.'
			},
			{
				id: '109',
				text: '$109 can provide education funding for our police legatees from day care to tertiary studies. We aim to support our legatee children through all stages of their education.'
			},
			{
				id: 'other',
				text: 'Your donation will help us care for and support the NSW police family'
			}
		];

		function donationAmountClick() {
			var $this = $(this);
			var value = $this.val();

			$donationAmountButtons.removeClass('active');
			$this.addClass('active');

			setDonationAmount(value);
			showDescription(value);
		}

		/***
		 * Show different descriptions depending on the donation amount selected.
		 * @param {string} value The donation amount selected.
		 */
		function showDescription(value) {
			var description;

			$description.removeClass('active');

			description = descriptionTexts.filter(function (obj) {
				return obj.id === value.toLowerCase();
			});

			$description.html(description[0].text);
			$description.addClass('active');
		}

		function setDonationAmount(value) {
			if (value.toLowerCase() !== 'other') {
				$donationAmount.val(value).prop('disabled', true);
			} else {
				$donationAmount.val('').prop('disabled', false).focus();
			}
		}

		function bindEvents() {
			$donationAmountButtons.on('click', donationAmountClick);

			$creditCardLink.on('click', function (e) {
				e.preventDefault();
				donatePageCreditCardUrl = donatePageCreditCardUrl + '?amount=' + $donationAmount.val();
				window.location = donatePageCreditCardUrl;
			});

			$directDebitLink.on('click', function (e) {
				e.preventDefault();
				donatePageDirectDebitUrl = donatePageDirectDebitUrl + '?amount=' + $donationAmount.val();
				window.location = donatePageDirectDebitUrl;
			});

			/***
			 * Clicking the wrapper submits the form.
			 * This lets the paragraph tag behave the same as the credit card/direct debit anchors.
			 */
			$paypalDonateWrapper.on('click', function () {
				$paypalAmount.val($donationAmount.val());
				$paypalForm.submit();
			});

			/***
			 * Grab the pages to redirect to depending on the payment type chosen.
			 * These urls are set as data attributes on the donate button.
			 */
			$donateModal.on('show.bs.modal', function (e) {
				var $button = $(e.relatedTarget);
				donatePageCreditCardUrl = $button.data('donatePageCreditCard');
				donatePageDirectDebitUrl = $button.data('donatePageDirectDebit');
				showDescription('69');
			});
		}

		function init() {
			bindEvents();
		}

		init();
	});


});

